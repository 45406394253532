import React from 'react'
import "./about.css"
import ABOUT from "../../assets/il_1588xN.3634516475_3n41.webp"
import {FaAward} from "react-icons/fa"
import {TbFileCertificate} from "react-icons/tb"
import {TiFolderOpen} from "react-icons/ti"

// <article> The HTML <article> tag is used to define a self-contained block of 
// content, such as a blog post, a news article, or a product review. It is 
// intended to be used for content that could be distributed or reused 
// independently of the rest of the page or site.
// When you use the <article> tag, you are indicating to search engines 
// and other web technologies that the content within it is a standalone 
// piece of content, rather than just a section of the page. This can be 
// helpful for improving search engine optimization (SEO), as search engines
//  may give more weight to the content within an <article> tag

const About = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>

      <div className = "container about__container">
        <div className="about__me">
          <div className='about__me-image'>
            <img src = {ABOUT} alt="about image"></img>
          </div>
        </div>

        <div className='about__content'>

          <div className='about__cards'>

            <article className="about__card">
              <FaAward className = "about__icon"/>
              <h5>Experience</h5>
              <small>4+ Years Experience</small>
            </article>

            <article className="about__card">
              <TbFileCertificate className = "about__icon"/>
              <h5>Certifications</h5>
              <small>5+ <br></br> completed</small>
            </article>

            <article className="about__card">
              <TiFolderOpen className = "about__icon"/>
              <h5>Projects</h5>
              <small>10+ completed</small>
            </article>
          </div>

          <p>
          I am passionate about using my 
          software and web knowledge and analytical skills in real-world projects. 
           I am experienced in developing projects using various 
           programming languages and a variety of advanced technologies.
          In addition to my skills in coding, I am also proficient in other areas such
 as project analysis, and 
 quality assurance. My involvement in various agile projects has helped me 
 become quite confident in working in agile environments. I have a thorough 
 understanding of the Software Development Life Cycle (SDLC) and the Software 
 Testing Life Cycle (STLC). I am always up for challenges, enthusiastic about learning new technologies and advancing my skills, and love to solve problems of any complexity level.
          </p>

          <a href="#contact" className="btn btn-primary">Let's talk</a>


        </div>
        
      </div>
        
      </section>
    
     
   
  )
}

export default About