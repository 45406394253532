
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";



// import required modules
import { Pagination } from "swiper";

import "./projects.css"
import IMG1 from  "../../assets/images.png"
import IMG2 from  "../../assets/images.png"
import IMG3 from  "../../assets/images.png"


// for making the projects slides, we are going to use swiperjs//



// const Projects = () => {
//   return (
//     <section id ="projects">

//       <h5>Review from clients</h5>
//       <h2>Projects</h2>

//       <div className='container projects__container'>
//         <article className='project'>
//           <div className='client__avatar'>
//             <img src= {IMG1} alt = "AVATAR ONE"/>
//           </div>
//           <h5 className='clinet__name'>Luna Sranski</h5>
//             <small className='client__review'>
//               vdfvdfbfgbfgbfbfbdedbehfhbf erfv e vfgvgerhj
//               vdfhvdfvhdfgvhdfvfdvdfhvhdbhjfdbvhdfjbvhdjfv
//               fdhvbdfhjvvdhgfgdnhgngmm mn ghnbdvcdsfsrg
//             </small>
//         </article>


//         <article className='project'>
//           <div className='client__avatar'>
//             <img src= {IMG1} alt = "AVATAR ONE"/>
//           </div>
//           <h5 className='clinet__name'>Luna Sranski</h5>
//             <small className='client__review'>
//               vdfvdfbfgbfgbfbfbdedbehfhbf erfv e vfgvgerhj
//               vdfhvdfvhdfgvhdfvfdvdfhvhdbhjfdbvhdfjbvhdjfv
//               fdhvbdfhjvvdhgfgdnhgngmm mn ghnbdvcdsfsrg
//             </small>
//         </article>


//         <article className='project'>
//           <div className='client__avatar'>
//             <img src= {IMG1} alt = "AVATAR ONE"/>
//           </div>
//           <h5 className='clinet__name'>Luna Sranski</h5>
//             <small className='client__review'>
//               vdfvdfbfgbfgbfbfbdedbehfhbf erfv e vfgvgerhj
//               vdfhvdfvhdfgvhdfvfdvdfhvhdbhjfdbvhdfjbvhdjfv
//               fdhvbdfhjvvdhgfgdnhgngmm mn ghnbdvcdsfsrg
//             </small>
//         </article>


//       </div>
//     </section>
//   )
// }

// export default projects

// -----------------------------------------------------------------------

// uper code is hard coded so we need to make it dynamic


const data = [
  {image: IMG1,
    name: "Smart Medicine Dispenser",
    review: "Collaborated with an Agile team, following the SDLC methodology, to develop a smart medicine alert device equipped with an LED and TFT screen alarm system and a desktop application, developed using Python, providing timely medication reminders to patients, and improving health outcomes."

  },
  {image: IMG2,
    name: "Real Estate Application",
    review: "Produced an application via Python and MYSQL, aiding customers in buying and selling properties. Designed ER model and translated it to a Relational Schema. Leveraged relational schema as a database in MySQL Workbench, and performed data mining using linear regression, obtaining statistically significant attributes."
    },
    {image: IMG3,
      name: "Smart Blister Medicine pack",
      review: "Implemented a smart blister medicine pack that used NFC tag, raspberry pi with PN532 NFC reader, ThingSpeak cloud platform, and Python to improve patient well-being by ensuring they adhered to their medication schedule."
      }
  
]
const Projects = () => {
  return (
    <section id ="projects">

      <h5>Description of some projects</h5>
      <h2>Project Descriptions</h2>

     
      <Swiper className='container projects__container slider_container mySwiper' 
      pagination={{clickable: true,}} modules={[Pagination]} spaceBetween={20}
      slidesPerView={1}
      >
      {
        data.map(({image, name, review}, index) => {
          return (

          <SwiperSlide key ={index} className='project swiper-slide'>
          <div className='client__avatar'>
            <img src= {image} alt = "AVATAR ONE"/>
          </div>
          <h5 className='clinet__name'>{name}</h5>
            <small className='client__review'>
              {review}
            </small>
          </SwiperSlide>

          )
        })
      }


      </Swiper>

    
    </section>
  )
}

export default Projects




// The key in this code is used to give each item in the data array a unique identifier. 
// When React renders a list of items, it needs a way to differentiate between them so that 
// it can efficiently update the DOM when changes occur.

// By providing a key prop, React can keep track of which items have changed, been added, 
// or been removed. The key should be a unique value for each item in the list, such as an 
// ID or an index.

// In this code, the key is set to the index of each item in the data array. While
//  using an array index as a key is convenient, it can cause issues if the order
//   of the items changes or if new items are added or removed from the middle of
//   the list. In those cases, it's better to use a more stable unique identifier as the key.


