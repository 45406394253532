import React from "react"
import "./contact.css"
import {HiOutlineMail} from "react-icons/hi"
import {AiFillLinkedin} from "react-icons/ai"
import {BsWhatsapp} from "react-icons/bs"

import { useRef } from 'react';
import emailjs from 'emailjs-com';


const Contact = () => {

  const form =useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ep60pr9', 'template_lxflf6c', form.current, 'o8bfAqrrHLNqWtqDq')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };
  return (
    <section id = "contact">
      <h5>Get In Touch</h5>
      <h2>Contact me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <HiOutlineMail className="contact__option-icon"/>
            <h4>Email</h4>
            <h5>mjavadi@uwaterloo.ca</h5>
            <a href="mailto:mjavadi@uwaterloo.ca" target="-blank">Send a Message</a>
          </article>

          <article className='contact__option'>
            <AiFillLinkedin className="contact__option-icon"/>
            <h4>Linkedin</h4>
            <h5>MahsaJavadi</h5>
            <a href="https://www.linkedin.com/in/mahsajavadi63/" target="-blank">Send a Message</a>
          </article>

          {/* <article className='contact__option'>
            <BsWhatsapp className="contact__option-icon"/>
            <h4>WhatsApp</h4>
             <h5>519-590-2823</h5> 
            <a href="https://api.whatsapp.com/send?phone=+15195902823" target="-blank">Send a Message</a>
          </article> */}
        </div>

      

        <form ref = {form} onSubmit={sendEmail}>
         <input type='text' name="name" placeholder='Your Full Name' required/>
         <input type='email' name="email" placeholder='Your Email' required/>
         <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
         <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>

      </div>

    </section>
  )
}

export default Contact