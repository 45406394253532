import React from 'react'
// import Resume from '../../assets/Resume.pdf'



// /* for a file to be downloadabe I add download attribute */
/* #contact : this will take us to the contact component. with this we are going to get the contact section an id of contact */

const CTA = () => {
  return (
    <div className = 'cta'>
        
        {/* <a href={Resume} download className = "btn"> Download Resume</a> */}
        <a href = "#contact" className="btn btn-primary"> Let's talk</a>
    </div>
  )
}

export default CTA