import React from 'react'
import './services.css'
import {BiCheck} from "react-icons/bi"

function Services() {
  return (
    <section id ="services">

      <h5>What I offer</h5>
      <h2>Services</h2>

      <div className="container service__container">
        {/* <article className='service'>
          <div className='service__head'>
            <h3>UI/UX Design</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Responsive design for mobile devices and tablets</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Wireframing and prototyping</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>lloremfgvfgbdhgnfchncg</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>loremfgvfgbdhgnfchncg</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>loremfgvfgbdhgnfchncg</p>
            </li>
          </ul>
        </article> */}

        {/* END OF UI/UX */}


        <article className='service'>
          <div className='service__head'>
            <h3>Software Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Full-stack software development</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Database development and management</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Software testing and quality assurance</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Custom software development using Python, and javaScript</p>
            </li>
            {/* <li>
              <BiCheck className="service__list-icon"/>
              <p>loremfgvfgbdhgnfchncg</p>
            </li> */}
          </ul>
        </article>

        {/* END OF software DEVELOPMEN  */}

        <article className='service'>
          <div className='service__head'>
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Back-end web development using Node.js, Express, Python</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Web application development using popular frameworks like React</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>Front-end web development using HTML, CSS, and JavaScript</p>
            </li>
            {/* <li>
              <BiCheck className="service__list-icon"/>
              <p>loremfgvfgbdhgnfchncg</p>
            </li>
            <li>
              <BiCheck className="service__list-icon"/>
              <p>loremfgvfgbdhgnfchncg</p>
            </li> */}
          </ul>
        </article>

        {/* END OF WEB development  */}

      </div>
    </section>
  )
}

export default Services