import React from 'react'
import './nav.css'
import {AiOutlineHome} from "react-icons/ai"
import {AiOutlineUser} from "react-icons/ai"
import {BiBook} from "react-icons/bi"
import {RiServiceLine} from "react-icons/ri"
import {BiMessageAltMinus} from "react-icons/bi"
import { useState } from 'react'

{/* href = "#" this will take us to the top of teh page */}
//<a href = "#about"> forexample this about id redirect us 
// to teh about section, so in About component we define 
// an id= "about" for this section

const Nav = () => {

  const[activeNav, setActiveNav] = useState("#")
  return (
    <nav>

        <a href = "#" onClick={()=>setActiveNav("#")} className={activeNav === "#" ? "active" : ""}> <AiOutlineHome/></a>
        <a href = "#about" onClick={()=> setActiveNav("#about")} className={activeNav === "#about" ? "active" : ""}> <AiOutlineUser/></a>
        <a href = "#experience" onClick ={()=> setActiveNav("#experience")}className= {activeNav === "#experience" ? "active" : ""}> <BiBook/></a>
        <a href = "#services" onClick ={()=>setActiveNav("#services")} className={activeNav === "#services" ? "active" : ""}> <RiServiceLine/></a>
        <a href = "#contact" onClick={()=>setActiveNav("#contact")} className={activeNav === "#contact" ? "active" : ""}> <BiMessageAltMinus/></a>
    </nav>
  )
}



export default Nav