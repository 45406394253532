import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {SiHackerrank} from 'react-icons/si'

// when we say  target = "_blank" it open that tag in a new tab
const HeaderSoacials = () => {
  return (
    <div className='header__social'>
        <a href="https://www.linkedin.com/in/mahsajavadi63/" target ="_blank"><BsLinkedin/></a>
        <a href="https://github.com/mahsa199170" target ="_blank"><FaGithub/></a>
        {/* <a href="https://dribbble.com/tags/website" target ="_blank"><SiHackerrank/></a> */}

    </div>
  )
}

export default HeaderSoacials