import React from 'react'
import "./portfolio.css"
import IMG1 from "../../assets/medication-reminder-apps.jpeg"
import IMG2 from "../../assets/mls-apps.webp"
import IMG3 from "../../assets/AI.jpeg"
import IMG4 from "../../assets/prtfolioweb.png"
import IMG5 from "../../assets/on-demand-home-services-schedule-service.jpeg"

// target="_blank" this will open the link in a new tab



// const Portfolio= () => {
//   return (
//     <section id="portfolio">
//       <h5>My Recent Work</h5>
//       <h2> Portfolio</h2>


//       <div className="container portfolio__container">
//         <article className='portfolio__item'>
//           <div className='portfolio__item-image'>
//           <img src = {P} alt=""/>
//           </div>
//           <h3>This is a portfolio item title</h3>
//           <div className='portfolio__item-cta'>
//           <a href= "https://github.com/mahsa199170" className='btn' target="_blank">Github</a>
//           <a href= "" className='btn btn-primary' target="_blank">Live Demo</a>
//           </div>
//         </article>

//         <article className='portfolio__item'>
//           <div className='portfolio__item-image'>
//           <img src = {P} alt=""/>
//           </div>
//           <h3>This is a portfolio item title</h3>
//           <div className='portfolio__item-cta'>
//           <a href= "https://github.com/mahsa199170" className='btn' target="_blank">Github</a>
//           <a href= "" className='btn btn-primary' target="_blank">Live Demo</a>
//           </div>
//         </article>

//         <article className='portfolio__item'>
//           <div className='portfolio__item-image'>
//           <img src = {P} alt=""/>
//           </div>
//           <h3>This is a portfolio item title</h3>
//           <div className='portfolio__item-cta'>
//           <a href= "https://github.com/mahsa199170" className='btn' target="_blank">Github</a>
//           <a href= "" className='btn btn-primary' target="_blank">Live Demo</a>
//           </div>
//         </article>

//         <article className='portfolio__item'>
//           <div className='portfolio__item-image'>
//           <img src = {P} alt=""/>
//           </div>
//           <h3>This is a portfolio item title</h3>
//           <div className='portfolio__item-cta'>
//           <a href= "https://github.com/mahsa199170" className='btn' target="_blank">Github</a>
//           <a href= "" className='btn btn-primary' target="_blank">Live Demo</a>
//           </div>
//         </article>


//         <article className='portfolio__item'>
//           <div className='portfolio__item-image'>
//           <img src = {P} alt=""/>
//           </div>
//           <h3>This is a portfolio item title</h3>
//           <div className='portfolio__item-cta'>
//           <a href= "https://github.com/mahsa199170" className='btn' target="_blank">Github</a>
//           <a href= "" className='btn btn-primary' target="_blank">Live Demo</a>
//           </div>
//         </article>

    
       


  
//       </div>
//     </section>
//   )
// }

// export default Portfolio


// -------------------------dynamic code----------------------------



// we are going to have an array of 6 portfolio item object that we are going to map through

// in the upper code we hard coded our portfolio items, but here we want to do it, dynamically
// using array map function



const data = [
  {
    id : 1,
    image: IMG1,
    title: "Smart Medicine Dispenser",
    github: "https://github.com/mahsa199170/smart-medicine-dispenser"

  },
  {
    id : 2,
    image: IMG2,
    title: "Real Estate Application",
    github: "https://github.com/mahsa199170/Real-Estate-application"

  },
  {
    id : 3,
    image: IMG3,
    title: "Enhanced Adversarial Attack Methods Against Machine Learning Models",
    github: "https://github.com/mahsa199170/create-adversarial-examples"
  },
  {
    id : 4,
    image: IMG4,
    title: "React Portfolio Website",
    github: "https://github.com/mahsa199170/Portfolio-Website-Using-React"

  },
  {
    id : 5,
    image: IMG5,
    title: "In-Home Service Web Application",
    github: "https://github.com/mahsa199170/In-Home-service"
  },


]

const Portfolio= () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2> Portfolio</h2>

      <div className="container portfolio__container">
        {

        data.map(({id,image,title,github})=>{
          return (
            <article key = {id}className='portfolio__item'>
            <div className='portfolio__item-image'>
            <img src = {image} alt={title}/>
            </div>
            <h3>{title}</h3>
            <div className='portfolio__item-cta'>
            <a href= {github} className='btn' target="_blank">Github</a>
            </div>
          </article>
          )
        })

        }
      </div>
    </section>
  )
}

export default Portfolio




// In this code, the key is set to the id of each item in the data array, 
// rather than the index. This is a better practice than using the array index as a key,
//  because it ensures that each item has a unique identifier even if the order of the items 
//  changes.

// The difference between this code and the code in project-description.jsx is that in this
//  code, the key is set to a unique identifier that is intrinsic to each item in the array
//   (id), while in the previous code, the key was set to the index of each item in the array
//   . Using an intrinsic identifier is generally preferred because it ensures that the key 
//   remains stable even if the order of the items changes.
// In both cases, the key is used to help React keep track of the
//  items in the list and efficiently update the DOM when changes occur.

