import React from 'react'
import './header.css'
import CTA from "./CTA"
import ME from '../../assets/mahsa-removebg-preview.png'
import HeaderSoacials from './HeaderSoacials'
// .header__container When naming a class and using double underscore "__" as a 
// separator between words, it is a part of the BEM (Block Element Modifier) naming convention 
// BEM is a methodology for organizing and naming CSS classes in a structured
//  and predictable way. It helps developers to create more modular, reusable,
//   and maintainable code by using a consistent naming convention that makes it 
//   easy to understand the purpose and relationship of each class.

const Header = () => {
  return (
    <header>
      <div className= "container header__container">
        <h5> Hello I'm</h5>
        <h1> Mahsa Javadi</h1>
        {/* <h5 className = ".text-light"> Software Developer</h5> */}
        <CTA/>
        <HeaderSoacials/>
        <div className="me">
          <img src = {ME} alt="me"></img>
        </div>

        <a href="#contact" className = "scroll__down">Scroll Down</a>
        

      </div>
    </header>
  )
}

export default Header