import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import React from 'react';

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);


// to keep everything simpel, i make an each section of page as a component, so we will have
// header component, about component, experience comonent and ....